/* Common css */
.footer {
  position: fixed;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  background: #000;
  width: 100%;
  height: 40px;
  gap: 10px;
  border-top: 2px solid white;
}

.footer h4 {
  color: wheat;
}

.footer a {
  color: white;
  text-decoration: none;
}
h1{
  font-size: 24px;

}
/* pricing */
.pricing-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 30vh;
  height: 100%;
}

.pricing-main h1 {
  background: wheat;
  width: 100%;
  height: 48px;
  font-size: 24px;
  display: flex;
  justify-content: center;
}

.pricing-div {
  display: flex;
  width: 90%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.pricing-card {
  display: flex;
  flex-direction: column;
  border: none;
  width: 30%;
  height: 190px;
  background: linear-gradient(120deg, #d6a130, #FBF5B7, #AA771C) !important;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}

.pricing-para {
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 60%;
  height: 190px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: linear-gradient(120deg, #d6a130, #FBF5B7, #AA771C) !important;
}

.pricing-para a {
  color: black;
  text-decoration: none;
}

.pricing-para button {
  background: black;
  width: 200px;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  font-weight: 900;
}

.pricing-para button:hover {
  background: rgb(255, 255, 255);
  color: black;
  cursor: pointer;
  font-weight: 900;
}

@media (min-width:951px) {
  .mobile-details{
    display:none !important;
  }
  .overlay {
    position: fixed;
   height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.404); /* Light black color */
    justify-content: center;
    align-items: center;
    z-index: 998;
 
    .form-div {
      display: flex;
      border: 1px solid rgb(255, 255, 255);
      border-radius: 10px;
      flex-direction: column;
      backdrop-filter: blur(5px);
      align-items: center;
      justify-content: center;
      width: 500px; /* Adjust width as needed */
      z-index: 10000;
      background: rgba(44, 43, 43, 0.424);
      padding-bottom: 20px;
      transform: scale(1.4);
    }
    .close-icon{
      position: absolute; /* Set position relative to the header */
      top: 10px; /* Adjust top position */
      right: 10px; /* Adjust right position */
      z-index: 10000;
      color: wheat;
      font-size: 20px;
      cursor: pointer;
      border: 1px solid white;
      border-radius: 50%;
    }
    .close-icon:hover{
      color: white;
    }
    .headers {
      background: #081933;
      width: 100%;
      height:126px;
      top: 0;
      position: fixed;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
  
      h4 {
        color: rgb(248, 248, 248);
      }
  
      h2 {
        color: rgb(228, 141, 248);
      }
    }
  
    .form-div form {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      margin-top: 150px;
    }
  
    .form-div form div {
      margin-bottom: 10px;
      width: 400px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  
      input {
        width: 210px;
        border: 1px solid black;
        border-radius: 5px;
      }
      input[type="date"] {
        width: 213px;
      }
      input[type="time"] {
        width: 93px;
      }
      label {
        color: rgb(255, 255, 255);
        font-weight: 600;
        font-size: 15px;
      }
    }
  
    .form-div form div select {
      background: white;
      width: 56%;
      border-radius: 2px;
    }
  
    option {
      border-radius: 0;
    }
  
  
    .form-submit {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background: rgb(89, 154, 245);
      padding: 5px 20px;
      border: white solid 2px;
      border-radius: 5px;
    }
  }
  


  /* for larger devices like desktop and laptop*/
  nav {
    display: flex;
    flex-direction: row;
    background: #000000;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 0;
    z-index: 999;
  }


  .navbar-center {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
  }
  .navbar-right{
    display: flex;
    img{
      width: auto;
      transform: translate(-8px , 0px);
    }
  }

  .call-button {
    background: #000000;
    color: white;
    border: 1px solid white;
    padding: 5px 15px;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;display: flex;
    align-items: center;
    img{
      height: 20px;
    }
  }
.form-div1{
  display: none;
}
  .navbar-buttons {
    color: rgb(255, 255, 255);
    border-radius: 7px;
    font-size: 16px;
    border: 0.5px solid rgb(255, 255, 255);
    background: #000;
    padding: 5px 10px;
    cursor: pointer;
  }

  .dropdown {
    display: none;
  }

  
  .hero {
    margin-bottom: -40px;
    background-image: url("../All Images/Home carousel/First Image.png");
    background-size: cover;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    .form-div-hero {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(-10px , 100px);
      display: flex;
      border: 1px solid rgb(255, 255, 255);
      border-radius: 10px;
      flex-direction: column;
      backdrop-filter: blur(10px);
      align-items: center;
      justify-content: center;
      width: 500px; /* Adjust width as needed */
      background: rgba(44, 43, 43, 0.424);
      padding-bottom: 20px;
    }
    .close-icon{
      position: absolute; /* Set position relative to the header */
      top: 10px; /* Adjust top position */
      right: 10px; /* Adjust right position */
      z-index: 10000;
      color: wheat;
      font-size: 20px;
      cursor: pointer;
      border: 1px solid white;
      border-radius: 50%;
    }
    .close-icon:hover{
      color: white;
    }
    .headers-hero {
      background: #081933;
      width: 100%;
      height:126px;
      top: 0;
      position: fixed;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
  
      h4 {
        color: rgb(248, 248, 248);
      }
  
      h2 {
        color: rgb(228, 141, 248);
      }
    }
  
    .form-div-hero form {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      margin-top: 150px;
    }
  
    .form-div-hero form div {
      margin-bottom: 10px;
      width: 400px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  
      input {
        width: 210px;
        border: 1px solid black;
        border-radius: 5px;
      }
      input[type="date"] {
        width: 213px;
      }
      input[type="time"] {
        width: 93px;
      }
      label {
        color: rgb(255, 255, 255);
        font-weight: 600;
        font-size: 15px;
      }
    }
  
    .form-div-hero form div select {
      background: white;
      width: 56%;
      border-radius: 2px;
    }
  
    option {
      border-radius: 0;
    }
  
  
    .form-submit-hero {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background: rgb(89, 154, 245);
      padding: 5px 20px;
      border: white solid 2px;
      border-radius: 5px;
    }
    div{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      .img1{
        width: 100%;
        margin-top: 50px;
        height: calc(100vh -60px);
      }
      .img2{
        display: none;
      }
    }
  }
  

  

  .hero img {
    height: 100%;
    width: fit-content;
  }

/* overlay-all */
.overlay-all{
  position: fixed;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.651); /* Light black color */
  justify-content: center;
  align-items: center;
  z-index: 998;
  display: flex;

  .form-div {
    display: flex;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    flex-direction: column;
    backdrop-filter: blur(5px);
    align-items: center;
    justify-content: center;
    width: 500px; /* Adjust width as needed */
    z-index: 10000;
    background: rgba(44, 43, 43, 0.424);
    padding-bottom: 20px;
    transform: scale(1.3);
  }
  .close-icon{
      position: absolute; 
      top: 10px; 
      right: 10px; 
      z-index: 10000;
      color: wheat;
      display: flex;
      font-size: 20px;
      cursor: pointer;
      border: 1px solid white;
      border-radius: 50%;
  }
  .close-icon:hover{
    color: white;
  }
  .headers {
    background: #081933;
    width: 100%;
    height:95px;
    top: 0;
    position: fixed;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    h4 {
      color: rgb(248, 248, 248);
    }

    h2 {
      color: rgb(228, 141, 248);
    }
  }

  .form-div form {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 125px;
  }

  .form-div form div {
    margin-bottom: 10px;
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input {
      width: 210px;
      height: 25px;
      border: 1px solid black;
      border-radius: 5px;
    }
    input[type="date"] {
      width: 213px;
    }
    input[type="time"] {
      width: 93px;
    }
    label {
      color: rgb(255, 255, 255);
      font-weight: 600;
      font-size: 15px;
    }
  }

  .form-div form div select {
    background: white;
    width: 56%;
    border-radius: 2px;
  }

  option {
    border-radius: 0;
  }


  .form-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgb(89, 154, 245);
    padding: 5px 20px;
    border: white solid 2px;
    border-radius: 5px;
  }
}

  
 /* project specs  */
 .project-specs {
  height: 100%;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: wheat;
    width: 100%;
    height: 48px;
  }

  .specs {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .specs-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; /* Center the content vertically */

    img {
      width: 450px;
      height: auto;
      border-top-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    ul {
      list-style-type: none;
      text-align: left;
      line-height: 35px;
    }
  }

  .floor-plan-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;margin-bottom: 40px;
  }

  .left-plan,
  .right-plan {
    position: relative;
    height: auto;
    width: auto;
    margin-bottom: 1rem; /* Add some margin between the image and the button */
    img{
      width: 75%;
      filter: blur(4px);
    }
  }

  .center-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #0000008e;
    color: #fff; /* Set text color to white */
    padding: 10px 20px; /* Adjust padding as needed */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 400px;
    height: 50px;
    font-size: 25px;
    align-items: center;
    .cursor-click{
       color: rgb(40, 219, 210);
       transform: translate();
    }
  }
}






  /* FloorPlan css */
  .floorplan-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-container {
    width: 600px;
    img {
      
      height: auto;
      width: 100%;
    }
  }

 
  /* Location Div */
  .location-div {
    h1 {
      color: #000;
    }

    img {
      height: 670px;
      width: auto;
    }
  }
  /* pricing */ 

  .pricing-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 30vh;
    height: 100%;
    h1{
      background: wheat;
      width: 100%;
      height: 48px;
      /* align-items:normal; */
      font-size: 24px;
      display: flex;
      justify-content: center;
    
    }
      .pricing-div{
        display: flex;
        width: 90%;
        flex-direction: row;
        align-items: center;
      
        justify-content: space-around;
        .pricing-card{
          display: flex;
          flex-direction: column;
          border:none;
          width: 30%;
          height: 190px;
          background: linear-gradient(120deg, #d6a130, #FBF5B7, #AA771C) !important;
          border-top-left-radius: 20px;
          border-bottom-right-radius: 20px;
          cursor: pointer;
        }
        .pricing-para{
          display: flex;
          flex-direction: column;
          align-items: center;
          place-content: center;
          width: 60%;
          height: 190px;
          border-top-right-radius: 20px;
          border-bottom-left-radius: 20px;
          background: linear-gradient(120deg, #d6a130, #FBF5B7, #AA771C) !important;
         a{
          color: black;
          text-decoration: none;
         }
          button{
            background: black;
            width: 200px;
            color: white;
            padding: 10px 20px;
            border-radius: 20px;
            border: none;
            font-weight: 900;
          }
          button:hover{
            background: rgb(255, 255, 255);
            color: black;
            cursor: pointer;
            font-weight: 900;
          }
        }
      }
      
  }
  

  
  
  
  /* amenities */
  .amenities-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 60vh;
    height: auto;
    
  }
  h1 {
    margin: 0;
    background: wheat;
    width: 100%;
    height: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  span {
    font-size: 15px;
    text-align: center;
    margin-left: 5px;

  }

  .amenities-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;

    .amenities-img {
      position: relative;
      z-index: 1;
      

      img {
        width: 350px;
      }

      .text-overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        /* Adjust opacity as needed */
        color: white;
        padding: 5px;
        width: 340px;
      }
    }
  }
/* Location-specs */
.location-specs{

  padding-top: 20px;
  margin-bottom: 30px;
  h1{
    font-size: 24px;
    background: wheat;
    height: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  .nearby{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }
  .location-image{
    width: calc(100% - 55%);
    /* padding-left: 20px; */
    img{
      border: 2px solid black;
      height: auto;
      width: calc(100%);
    }
    img:hover{
      width: 100%;
      height: 100%;
      z-index: 1000;
      cursor: pointer;
    }

  }
  .nearby-cards{
    display: flex;
    flex-direction: column;
    width: calc(100% - 55%);
    height: 100%;
    background: #fff;

  }
  .top-cards{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-bottom: 50px;
    gap: 20px;
  }
  .bottom-cards{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
  }
  .card {
  background: #f0f0f0; /* Light gray background */
  border-radius: 10px;
  width: calc(100% - 20.33%);
  box-shadow: 5px 5px 10px #c8c8c8, -5px -5px 10px #ffffff; /* Neumorphic shadow */
  border: 1px solid #e0e0e0; /* Slightly darker than the background */
    h2{
      font-size: 18px;
      font-weight: 600;
      font-family:Georgia, 'Times New Roman', Times, serif;
    }
    p{
      padding-left: calc(100% - 80%);
      text-align: left;
    }
    }
}
 /* contact us  */
.contact-main{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 350px;
  /* padding-left: 20px; */
  background: #000;
  margin-bottom: 40px;
  

  .contact-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;    
    img{
      width: 270px
      
    };
  }
  .contact-address{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    h1{
      background: none;
      font-size: 24px;
      text-align: center;
      align-items: center;

    }
    p{
      background: none;
      text-align: center;
    }
    
  }
  
  .contact-us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;  
    h1{
      align-items: center;
      background: none;
    }
    a{
      color: white;
      text-decoration: none;align-items: center;
    }
    
  }
}
}
.mail-icon{
  color: white;
  font-size: 25px;
  align-items: center;
  margin-bottom: -5px;
}
.whatsapp-icon{
  color:#25d366;
  font-size: 25px;
  margin-bottom: -5px;
}
@media (width<951px) {
  /* for smaller devices like mobile phones*/

  nav {
    display: flex;
    flex-direction: row;
    background: #000000;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .logo {
    img {
      height: 30px;
      width: auto;
      margin-left: 10px;
    }
  }

  .navbar-center {
    display: none;
  }

  .navbar-buttons {
    color: rgb(255, 255, 255);
    border-radius: 7px;
    font-size: 11px;
    border: 0.5px solid rgb(255, 255, 255);
    background: #000;
    padding: 5px 10px;
  }

  .call-button {
    display: none;

  }

  .dropdown {
    color: white;
    font-size: 30px;
    display: flex;
    gap: 30px;
    margin-right: 10px;
    .menu{
      font-size: 35px;
    }
  }
.headset{
  position: absolute;
  align-items: center;
  height: 1em;
  width: 1em;
  font-size: 20px;
  color: white;
  /* background: wheat; */
  padding: 2px;
  transform: translate(50px , -11px );

}
  .dropdown-content {

    position: absolute;
    background-color: #e6e3e3;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    transform: translate(-120px, 10px);
  }

  .dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background-color: #fefefe;
    margin: 2px;
    border: none;
    width: 98%;
    text-align: center;
    cursor: pointer;
  }

  .dropdown-content button:hover {
    background-color: #ddd;
  }
/* form component */


 /* floorplan */
 .floorplan-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-container {
  width: 100%;

  img {
    height: auto;
    width: 100%;
  }
}




/* location-div */
.location-div{
  img{
    width: 100%;
  }
}
/* amenities */
.amenities-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
  gap: 1px;

  .amenities-img {
    position: relative;

    img {
      width: 350px;
    }

    .text-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      /* Adjust opacity as needed */
      color: white;
      padding: 5px;
      width: 340px;
    }
  }
}


}
@media (min-width: 0px) and (max-width: 950px){


/* homesection */
    .hero{
        position: relative;
        margin-bottom: 20px;
        background-image: url("../All Images/Home carousel/high-building.jpg");
        backdrop-filter: blur(10px);
        width: 100%;

        background-size: cover;
        height: 100vh;
        background-position:center;
        background-repeat: no-repeat;

       .form-container-hero{
        display: none;
       }


      div{
        .img1{
        display: none;
        }
        .img2{
          width: 100%;
        }
      }
      .mobile-details{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          z-index: 99999;
          color: white;
          .header-hero-mobile{
            background: #0000007a;
            text-align: left;
            padding: 10px;
          }
          .tlr-heading{
            color: #fab947;
            font-size: 28px;
          }
          span{
            color: wheat;
            font-size: 17px;
          }
          .banner-btns{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .btn{
              text-align: center;
              align-items: center;
              font-weight: 700;
              color: black;
              text-decoration: none;
              padding: 2px 10px;
              background: #fab947;
              border-radius: 5px;
              border: none;
              outline: none;
            }
          }
          .enquiry-form{
            background: #000;
            width: 400px;
            height:auto;
            padding: 10px 0;
            .new-invest{
              background: #fab947;
              font-size: px;
              color: black;
            }
          }
          .hero-form-content{
            align-items: center;
            input{
              display: block;
              width: 92%;
              padding: .375rem .75rem;
              font-size: 1rem;
              line-height: 1.5;
              color: #212529;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              appearance: none;
              border-radius: .25rem;
              transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
              border: none;
              box-shadow: none;
              outline: 0;
              margin-bottom: 1rem;
              color: #000000;
              font-weight: 600;
              font-size: 16px;
              border-radius: 0;
              border-left: 5px solid rgb(166, 88, 255);
            }
            .form-group{
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              
              }
            .form-group1{
              display: flex;
              flex-direction: row;
              justify-content: center;
              gap: 5px;
              align-items: center;
              
              
              .plus-text{
                height: 1.5;
                color: wheat;
                font-size: 20px;
                font-weight: 600;
                text-align: center;
              }
            }
            .captcha-input{
              display: flex;
              padding: .375rem .75rem;
              border-left: none;
              width: 100px;
              margin-bottom: 0;
              border-radius: 5px;
              
            }
          }
          .hero-form-submit{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 5px 20px;
            button{
              background: #fab947;
              color: black;
              font-weight: 700;
              padding: 5px 10px;
              border-radius: 5px;
              border: none;
              outline: none;
            
            }
          }
        }

        
      .overlay{
        display: flex !important;
        
        z-index: 10000;

        .form-div{
          position: absolute;
          top: 0;
          transform: translate(5% , 130px);
          display: flex;
          border: 1px solid rgb(255, 255, 255);
          border-radius: 10px;
          flex-direction: column;
          backdrop-filter: blur(10px);
          align-items: center;
          justify-content: center;
          width: 90%;
          height: 70%;
          background: rgba(44, 43, 43, 0.424);
          padding-bottom: 20px;
        }
        .close-icon{
          position: absolute; /* Set position relative to the header */
          top: 10px; /* Adjust top position */
          right: 10px; /* Adjust right position */
          z-index: 10000;
          color: wheat;
          font-size: 20px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          display: flex;  
          border: 1px solid white;
          border-radius: 50%;
        }
        .close-icon:hover{
          color: white;
        }
        .headers {
          background: #081933;
          width: 100%;
          height:126px;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          position: fixed;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
      
          h4 {
            color: rgb(248, 248, 248);
          }
      
          h2 {
            color: rgb(228, 141, 248);
          }
        }
      
        .form-div form {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: white;
          margin-top: 150px;
          width: 100%;
        }
      
        .form-div form div {
          margin-bottom: 10px;
          display: flex;
          width: 90%;
          flex-direction: column;
          justify-content: space-between;
      
          input {
            width: 100%;
            height: 30px;
            text-align: center;
            border: 1px solid black;
            border-radius: 5px;
          }
          input[type="date"] {
            width: 213px;
          }
          input[type="time"] {
            width: 93px;
          }
          label {
            color: rgb(255, 255, 255);
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 20px;
          }
        }
      
        .form-div form div select {
          background: white;
          width: 56%;
          border-radius: 2px;
        }
      
        option {
          border-radius: 0;
        }
      
      
        .form-submit {
          display: flex;
          flex-direction: row;
          justify-content: center;
          background: rgb(89, 154, 245);
          padding: 5px 20px;
          border: white solid 2px;
          border-radius: 5px;
        }
      }
    }

/* formcomponent */
.form-div{
display: flex;  

}
/* contact us */
  .contact-main{
    height: 880px;
    padding-top: 5px;
    .center-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
        }
        
      } 
      .center-div{
        display: flex;
        flex-direction: row;
      }
      .cd{
        display: flex;
        flex-direction: row;
        label{
          display: flex;
          align-items: center;
          width: 100px;
          font-weight:600;
        }
        h4{
          text-align: left;
          align-items: center;
          font-weight: 400;
        }
        h5{
          font-weight: 400;
        }
      }
  .right-div {
    display: none;
  }


/* project specs  */

.project-specs{
 
  height: 750px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  



  .left-plan{
    
    height: auto;
    width: auto;
    img{
      height: auto;
      width: 90%;
    }
  }
  .specs{
    display: flex;
    flex-direction: column;

  }
  .right-plan{
    height: auto;
    width: auto;
    
    img{
      height: auto;
      width: 90%;
    }
    
  }




}
.amenities-header span {
  font-size: 15px;

  text-align: center;
  align-items: center;
  margin-left: 5px;

}

    /* .contact-form{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      backdrop-filter: brightness(1.4);
      padding-bottom: 20px;
      border-radius: 20px;
      height: auto;
      width:90%;

      form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        div{
          display: flex;
          flex-direction:row;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
          width: 80%;
          label{
            color: black;
            font-weight: 600;
          }
        }
        input{
          width: 70%;
          height: 20px;
          border: 1px solid black;
          border-radius: 5px;
          padding: 5px;
        }
        textarea{
          width: 70%;
          height: 100px;
          border: 1px solid black;
          border-radius: 5px;
          padding: 5px;
        }
        button{
          width: 40%;
          height: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid rgb(0, 0, 0);
          border-radius: 5px;
          padding: 15px 5px;
          background: #ffffff;
          color: rgb(0, 0, 0);
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
 */


  /* location specs mobile view  */
 .location-specs{
  margin-bottom: 20px;
  .location-image{
    img{
      width: 98%;
      border: 1px solid black;
    }
  }
 }
 .nearby-cards {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: 20px;
}

.top-cards,
.bottom-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}
.bottom-cards{
  margin-top: 20px;
}
.card {
  background: #f0f0f0;
  border-radius: 10px;
  width: 70%;
  box-shadow: 5px 5px 10px #c8c8c8, -5px -5px 10px #ffffff;
  border: 1px solid #e0e0e0;
  padding: 15px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.card h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin-bottom: 10px; /* Add some space below heading */
}

.card p {
  font-size: 14px; /* Reduce font size for better readability */
  margin: 0; /* Remove default margin */
  text-align: left;
  padding-left: 20%;
  
}

    /* contact us  */
 .contact-main{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;

  background: #000;
  margin-bottom: 40px;
  padding-top: 40px;
  

  .contact-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center; 
    margin: 0 auto;   
    img{
      width: 180px;
      margin: 0 auto;

    };
  }
  .contact-address{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    h1{
      font-size: 24px;

      text-align: center;
    }
    p{
      text-align: center;
      
    }

  }

  .contact-us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;  
      a{
        color: white;
        text-decoration: none;align-items: center;
      }
      .mail-icon{
        color: white;
        font-size: 25px;
        align-items: center;
        margin-bottom: -5px;
      }
  }
 }




  
  .form-div1 {
    display: flex;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    flex-direction: column;
    backdrop-filter: blur(10px);
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width:370px;
    /* Adjust width as needed */
    z-index: 10;
    background: rgba(44, 43, 43, 0.424);
    padding-bottom: 40px;
    margin-bottom: 20px

  }

  .headers1 {
    background: #081933;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    h4 {
      color: rgb(248, 248, 248);
    }

    h2 {
      color: rgb(228, 141, 248);
    }
  }

  .form-div1 form {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 25px;
  }

  .form-div1 form .div1 {
    margin-bottom: 10px;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    input{
      width:210px;
      border: 1px solid black;
      border-radius: 5px;
    }
    input[type="date,time"]{
      width: 213px;
    }
    label{
      color:rgb(255, 255, 255);
      font-weight: 600;
      font-size: 24px;
    }
  }
  .divs2{
    display: flex;
    flex-direction: column;
    input{
      width:210px;
      border: 1px solid black;
      border-radius: 5px;
      height: 30px;
    }
    input[type="date"]{
      width: 213px;
    }
    input[type="time"]{
      width: 93px;
    }
    label{
      color:rgb(255, 255, 255);
      font-weight: 600;
      font-size: 29px;
    }
  }

  .form-div1 form div select {
    background: white;
    width: 56%;
    border-radius: 2px;
  }

  option {
    border-radius: 0;
  }

  .close-icon{
    padding: 5px;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .close-icon:hover {
    color: rgba(182, 215, 229, 0.547);
    cursor: pointer;
  }




  .form-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgb(89, 154, 245);
    padding: 5px 20px;
    border: white solid 2px;
    border-radius: 5px;
  }

  .hero img {
    height: 100%;
    width: fit-content;
  }

 /* project specs  */

 .project-specs{
 
  height: auto;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  



  .left-plan{
    
    width: auto;
    img{
      height: auto;
      width: 90%;
    }
  }
  .specs{
    display: flex;
    flex-direction: column;
.specs-2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  img{
      width: 90%;
      margin: 0 auto;
      height: auto;
      border-top-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
      ul{
        list-style-type: none;
        text-align: left;
        line-height: 35px;
      }
      
  }


  }
  .right-plan{
    height:auto;
    width: auto;
    
    img{
      height: auto;
      width: 90%;
        }   
      }
    }
 }

 @media (width<951px){
  
/* overlay-all */
.overlay-all{
  position: fixed;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.651); /* Light black color */
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(5% , 0%);
  }
  .form-div {
    display: flex;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    flex-direction: column;
    backdrop-filter: blur(5px);
    align-items: center;
    justify-content: center;
    width: 90%; /* Adjust width as needed */
    z-index: 10000;
    background: rgba(44, 43, 43, 0.424);
    padding-bottom: 20px;
    transform: scale(1);
  }
  .close-icon{
      position: absolute; 
      top: 10px; 
      right: 10px; 
      z-index: 10000;
      color: wheat;
      display: flex;
      font-size: 20px;
      cursor: pointer;
      border: 1px solid white;
      border-radius: 50%;
  }
  .close-icon:hover{
    color: white;
  }
  .headers {
    background: #081933;
    width: 100%;
    height:95px;
    top: 0;
    position: fixed;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    h4 {
      color: rgb(248, 248, 248);
    }

    h2 {
      color: rgb(228, 141, 248);
    }
  }

  .form-div form {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 125px;
  }

  .form-div form div {
    margin-bottom: 10px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input {
      width: 210px;
      height: 25px;
      border: 1px solid black;
      border-radius: 5px;
    }
    input[type="date"] {
      width: 213px;
    }
    input[type="time"] {
      width: 93px;
    }
    label {
      color: rgb(255, 255, 255);
      font-weight: 600;
      font-size: 15px;
    }
  }

  .form-div form div select {
    background: white;
    width: 56%;
    border-radius: 2px;
  }

  option {
    border-radius: 0;
  }


  .form-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgb(89, 154, 245);
    padding: 5px 20px;
    border: white solid 2px;
    border-radius: 5px;
  }
}


 
 /* project specs  */
 .project-specs {
  height: 100%;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: wheat;
    width: 100%;
    height: 48px;
  }

  .specs {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .specs-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; /* Center the content vertically */

    img {
      width: 450px;
      height: auto;
      border-top-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    ul {
      list-style-type: none;
      text-align: left;
      line-height: 35px;
    }
  }

  .floor-plan-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px ;
    align-items: center;
    width: 100%;margin-bottom: 40px;
  }

  .left-plan,
  .right-plan {
    position: relative;
    height: auto;
    width: auto;
    margin-bottom: 1rem; 
    img{
      width: 95%;
      filter: blur(2px);
    }
  }

  .center-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #0000008e;
    color: #fff; /* Set text color to white */
    padding: 10px 20px; /* Adjust padding as needed */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 250px;
    padding: 5px 0;
    height: 50px;
    font-size: 18px;
    align-items: center;
    .cursor-click{
       color: rgb(40, 219, 210);
       transform: translate(0 , 3px);
    }
  }
}
.pricing-main{
  margin-bottom: 20px;
}
.pricing-div {
  height: 44vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pricing-card, .pricing-para {
  width: 100%;
}
 }